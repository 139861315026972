<template>
  <el-dialog :title="title" :visible="dialogVisible" width="40%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-form ref="ruleForm" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="作物方案名称" prop="name">
        <el-input v-model="form.name" :disabled="isDisable"></el-input>
      </el-form-item>
      <el-form-item label="方案亮点" prop="shortTitle">
        <el-input v-model="form.shortTitle" :disabled="isDisable" max="20"></el-input>
      </el-form-item>
      <el-form-item label="种植作物" prop="cropsACategoryId">
        <el-select v-model="form.cropsACategoryId" placeholder="请选择" :disabled="bindState || isDisable" @change="onOptionChange">
          <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="作物品种" prop="cropsAId">
        <el-select v-model="form.cropsAId" placeholder="请选择" :disabled="bindState || isDisable">
          <el-option v-for="item in options3" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预估亩产" prop="cropsAPredictYield">
        <el-input-number v-model="form.cropsAPredictYield" type="number" :min="0"></el-input-number> 斤
      </el-form-item>
      <el-form-item label="积温要求" prop="accumulatedTemp">
        <el-input-number v-model="form.accumulatedTemp" type="number" :min="0"></el-input-number>℃
      </el-form-item>
      <!-- <el-form-item label="辅种植作物">
        <el-select v-model="form.cropsACategoryId" placeholder="请选择" :disabled="isDisable" @change="onOptionChange">
          <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="辅作物品种">
        <el-select v-model="form.cropsAId " placeholder="请选择" :disabled="isDisable" @change="onOptionChange2">
          <el-option v-for="item in options3" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="预估亩产">
        <el-input v-model="form.name" :disabled="isDisable"></el-input>
      </el-form-item> -->
      <el-form-item label="种植季" prop="seasonId">
        <el-select v-model="form.seasonId" placeholder="请选择" :disabled="bindState || isDisable">
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="土壤特性" prop="soilProperties">
        <el-input maxlength="200" type="textarea" autosize v-model="form.soilProperties" :disabled="isDisable"></el-input>
      </el-form-item>
      <el-form-item label="描述" prop="descriptions">
        <el-input type="textarea" autosize v-model="form.descriptions" :disabled="isDisable"></el-input>
      </el-form-item>
      <el-form-item label="图片">
        <el-upload
          action=""
          :class="{ hideUpload: hideUploadEdit }"
          ref="upload"
          :file-list="fileList"
          :limit="1"
          list-type="picture-card"
          accept="image/jpg,image/jpeg,image/png"
          :auto-upload="false"
          :on-change="fileChange"
          :on-remove="fileChange"
        >
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">只能上传bmp/jpg/jpeg/png文件，且不超过5MB</div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button v-if="!isDisable" type="primary" @click="onSubmit">{{ isEdit ? '修改' : '立即创建' }}</el-button>
        <el-button v-if="!isDisable" @click="closeMe">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { createSupply, seasonList, categoryList, updateSupply, getRequest } from '../api/api';
export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    isDisable: false,
    currentItem: null
  },
  components: {},
  computed: {
    title() {
      if (this.isDisable) {
        return '详情';
      }
      if (this.isEdit) {
        return '编辑种植方案';
      }
      return '新建种植方案';
    }
  },
  data() {
    return {
      hideUploadEdit: false,
      rules: {
        name: [{ required: true, message: '请输入作物方案名称', trigger: 'blur' }],
        shortTitle: [{ required: true, message: '请输入方案亮点', trigger: 'blur' }],
        // descriptions: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        cropsACategoryId: [{ required: true, message: '请选择种植作物', trigger: 'change' }],
        cropsAPredictYield: [{ required: true, message: '请输入预估亩产', trigger: 'blur' }],
        // cropsAId: [{ required: true, message: '请选择作物品种', trigger: 'blur' }],
        seasonId: [{ required: true, message: '请选择种植季', trigger: 'change' }]
      },
      form: {
        name: '',
        descriptions: '',
        seasonId: '',
        cropsACategoryId: '',
        cropsAPredictYield: '',
        cropsAId: '',
        shortTitle: '',
        accumulatedTemp: '',
        soilProperties: ''
      },
      options3: [],
      options2: [],
      options1: [],
      fileList: [],
      bindState: false
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.bindState = false;
        this.hideUploadEdit = false;
        this.form = {
          name: '',
          descriptions: '',
          seasonId: '',
          cropsACategoryId: '',
          cropsAId: '',
          shortTitle: '',
          accumulatedTemp: '',
          soilProperties: ''
        };
        this.fileList = [];
        this.getSeason();
        this.getCategoryList();
      }
    }
  },
  methods: {
    // onOptionChange2(e) {
    //   console.log(e);
    //   this.form.cropsAId  = e;
    //   console.log(this.op);
    // },
    getBindState() {
      getRequest('/api/operate/planting/scheme/isRelationLands', { id: this.currentItem.id })
        .then((res) => {
          console.log(res, '$$$');
          if (res) {
            this.bindState = true;
          }
        })
        .catch((error) => {});
    },
    onOptionChange(id) {
      this.form.cropsAId = '';
      this.options1.map((e) => {
        if (id == e.id) {
          this.options3 = e.species || [];
          // if (e.species && e.species.length) {
          //   this.form.cropsAId = e.species[0].id;
          // }
        }
      });
    },
    getDetail() {
      // if (this.currentItem.cropsAId) {
      //   this.currentItem.cropsAId = Number(this.currentItem.cropsAId);
      // }
      this.currentItem.cropsAId = this.currentItem.cropsAId || '';
      this.form = _.cloneDeep(this.currentItem);

      this.fileList = [{ name: '1', url: this.currentItem.imageUrl }];
      if (this.fileList && this.fileList.length) {
        this.hideUploadEdit = true;
      }
      if (this.isEdit) {
        this.getBindState();
      }
    },
    getSeason() {
      this.options2 = [];
      getRequest('/api/operate/planting/use/season/current', {})
        .then((res) => {
          res.map((e) => {
            this.options2.push({ value: e.id, label: e.name });
          });
        })
        .catch((error) => {});
    },

    getCategoryList() {
      this.options1 = [];
      getRequest('/api/operate/planting/scheme/category/list', {})
        .then((res) => {
          this.options1 = res;
          if (this.isEdit) {
            res.map((e) => {
              if (e.id == this.currentItem.cropsACategoryId) {
                this.options3 = e.species || [];
              }
            });
          }
          if (this.isEdit) {
            console.log(this.options3, '$$$$$$$$');
            this.getDetail();
          }
        })
        .catch((error) => {});
    },
    closeMe() {
      this.fileList = [];
      this.$refs.upload.clearFiles();
      this.$emit('update:dialogVisible', false);
    },
    updateList() {
      this.$emit('updateList');
    },
    fileChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 1;

      this.fileList = fileList;
    },
    onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (!valid) {
          return;
        }
        let toastList = [];
        if (!this.form.name) {
          toastList.push('请输入标题');
        }
        if (!this.form.shortTitle) {
          toastList.push('请输入方案亮点');
        }

        if (!this.form.cropsACategoryId) {
          toastList.push('请输入种植作物');
        }
        if (toastList.length) {
          this.$message({
            message: toastList.join(','),
            type: 'warning'
          });
          return;
        }
        this.form.cropsAName = '';
        this.options3.map((e) => {
          if (e.id == this.form.cropsAId) {
            this.form.cropsAName = e.name;
          }
        });

        let formData = new FormData();
        if (this.fileList && this.fileList.length && this.fileList[0].raw) {
          formData.append('mediaFiles', this.fileList[0].raw);
        }
        // if (this.fileList && this.fileList.length && this.fileList[0].raw) {
        formData.append('farmingVOS', null);
        // }
        formData.append('schemeVO', JSON.stringify({ ...this.form }));
        if (this.isEdit) {
          updateSupply(formData)
            .then((res) => {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.closeMe();
              this.updateList();
            })
            .catch((error) => {});
        } else {
          createSupply(formData)
            .then((res) => {
              this.$message({
                message: '创建成功',
                type: 'success'
              });
              this.closeMe();
              this.updateList();
            })
            .catch((error) => {});
        }
      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
