<template>
  <el-dialog
    :title="'新建' + title"
    v-if="dialogVisible"
    :visible="dialogVisible"
    width="60%"
    @close="closeMe"
    destroy-on-close
    :close-on-click-modal="false"
  >
    <mapContainerVue :mapVisible.sync="mapVisible" @select="mapSelect"></mapContainerVue>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item :label="title + '名称'" prop="title">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="选择地址">
        <el-row>
          <div v-if="mapInfo"> {{ mapInfo.pname }}/{{ mapInfo.cityname }}/{{ mapInfo.adname }}/{{ mapInfo.address }} </div>
          <el-button type="primary" @click="mapVisible = true">选择地址</el-button>
        </el-row>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item label="*主题图片">
        <el-upload
          action=""
          :class="{ hideUpload: hideUploadEdit }"
          ref="upload"
          :file-list="fileList"
          :limit="1"
          list-type="picture-card"
          accept="image/jpg,image/jpeg,image/png"
          :auto-upload="false"
          :on-change="fileChange"
          :on-remove="fileChange"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="服务方" prop="serviceOrgName">
        <el-input v-model="form.serviceOrgName"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contractName">
        <el-input v-model="form.contractName"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="contractPhone">
        <el-input v-model="form.contractPhone"></el-input>
      </el-form-item>

      <el-form-item :label="title + '时间'" prop="startDate">
        <el-date-picker
          v-model="startDate"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions1"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="报名时间" prop="applyStartDate">
        <el-date-picker
          v-model="applyStartDate"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions2"
        >
        </el-date-picker>
      </el-form-item>
      <quill-editor class="editor" :options="editorOption" :content="content" @change="onEditorChange" />
      <div style="height: 100px"></div>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ isEdit ? '修改' : '立即创建' }}</el-button>
        <el-button @click="closeMe">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import dayjs from 'dayjs';

import { quillEditor } from 'vue-quill-editor';
import mapContainerVue from './mapContainer.vue';
import { createMeeting, serviceDetail, uploadMeeting, createTraining, uploadTraining, getRequest } from '../api/api';
export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    currentId: '',
    type: ''
  },
  components: {
    quillEditor,
    mapContainerVue
  },
  computed: {
    title() {
      if (this.type == 'metting') {
        return '会议';
      } else if (this.type == 'training') {
        return '培训';
      }
    }
  },
  data() {
    return {
      hideUploadEdit: false,
      // 富文本编辑器配置
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
            ['blockquote', 'code-block'], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ direction: 'rtl' }], // 文本方向
            [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            // [{ font: ['songti'] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ['clean'], // 清除文本格式
            ['image', 'video'] // 链接、图片、视频
          ]
        },
        placeholder: '请输入正文'
      },

      pickerOptions1: {
        disabledDate: (time) => {
          if (this.applyStartDate && this.applyStartDate.length) return time.getTime() < new Date(this.applyStartDate[1]).getTime();
          else return;
        }
      },
      pickerOptions2: {
        disabledDate: (time) => {
          if (this.startDate && this.startDate.length) return time.getTime() > new Date(this.startDate[0]).getTime();
          else return;
        }
      },
      fileList: [],
      startDate: [],
      applyStartDate: [],
      mapVisible: false,
      content: '',
      richText: '',
      form: {
        title: '',
        address: '',
        serviceOrgName: '',
        contractPhone: ''
      },
      rules: {
        title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        serviceOrgName: [{ required: true, message: '请输入举办方', trigger: 'blur' }],
        contractName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        contractPhone: [
          {
            required: true,
            validator: this.validatePhone,
            trigger: 'blur'
          }
        ],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        startDate: [
          {
            validator: this.validateStartDate,
            required: true
          }
        ],
        applyStartDate: [
          {
            validator: this.validateApplyStartDate,
            required: true
          }
        ]
      },
      mapInfo: '',
      detailRes: {}
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.hideUploadEdit = false;
        (this.fileList = []), (this.startDate = []), (this.applyStartDate = []), (this.mapVisible = false), (this.content = ''), (this.richText = '');
        (this.form = {
          title: '',
          address: '',
          serviceOrgName: '',
          contractPhone: '',
          contractName: ''
        }),
          (this.mapInfo = '');
        if (this.isEdit) {
          this.getDetail();
        }
      }
    }
  },
  methods: {
    // 校验手机号
    validatePhone(rule, value, callback) {
      let msg = '';
      value = value && value.toString().replace(/\s+/g, '');
      if (!value) {
        msg = '请输入手机号码！';
      } else {
        let reg = /^[0-9]+.?[0-9]*/;
        if (!reg.test(value)) {
          msg = '请输入正确的手机号码！';
        }
      }
      if (msg) {
        callback(new Error(msg));
      } else {
        callback();
      }
    },
    validateStartDate(rule, value, callback) {
      this.startDate && this.startDate.length ? callback() : callback(new Error(`请选择${this.title}时间`));
    },
    validateApplyStartDate(rule, value, callback) {
      this.applyStartDate && this.applyStartDate.length ? callback() : callback(new Error('请选择报名时间'));
    },
    getDetail() {
      let url = '';
      if (this.type == 'metting') {
        url = '/api/operate/govserv/meeting/view';
      } else {
        url = '/api/operate/govserv/training/view';
      }
      getRequest(url, { id: this.currentId })
        .then((res) => {
          this.detailRes = res;
          (this.mapVisible = false), (this.content = res.content), (this.richText = '');
          this.form = {
            title: res.title,
            address: res.address,
            serviceOrgName: res.serviceOrgName,
            contractPhone: res.contractPhone,
            contractName: res.contractName
          };
          this.fileList = [{ name: '1', url: res.titleImgUrl }];
          if (this.fileList && this.fileList.length) {
            this.hideUploadEdit = true;
          }
          let mapI = {
            pname: res.province,
            location: {
              lng: res.lon,
              lat: res.lat
            },
            cityname: res.city,
            adname: res.county
          };
          this.mapInfo = mapI;

          this.startDate = [new Date(res.startDate), new Date(res.endDate)];
          this.applyStartDate = [new Date(res.applyStartDate), new Date(res.applyEndDate)];
        })
        .catch((error) => {});
    },
    mapSelect(e) {
      this.mapVisible = false;
      if (e) {
        this.mapInfo = e;
        this.form.address = e.name;
      }
    },
    fileChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 1;
      this.fileList = fileList;
    },
    closeMe() {
      this.fileList = [];
      this.$refs.upload.clearFiles();
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      let msg = '';
      if (!this.mapInfo) {
        msg = '请选择地址';
      }
      if (!msg && (!this.fileList || this.fileList.length == 0)) {
        msg = '请选择图片';
      }
      if (msg) {
        this.$message({
          message: msg,
          type: 'warning'
        });
      } else {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let params = { ...this.form };
            params.startDate = this.startDate[0] ? dayjs(this.startDate[0]).format('YYYY-MM-DD HH:mm:ss') : '';
            params.endDate = this.startDate[1] ? dayjs(this.startDate[1]).format('YYYY-MM-DD HH:mm:ss') : '';
            params.applyStartDate = this.applyStartDate[0] ? dayjs(this.applyStartDate[0]).format('YYYY-MM-DD HH:mm:ss') : '';
            params.applyEndDate = this.applyStartDate[1] ? dayjs(this.applyStartDate[1]).format('YYYY-MM-DD HH:mm:ss') : '';
            params.content = this.richText.html;
            params.province = this.mapInfo.pname;
            params.city = this.mapInfo.cityname;
            params.county = this.mapInfo.adname;
            params.lon = this.mapInfo.lng;
            params.lat = this.mapInfo.lat;

            let formData = new FormData();
            if (this.fileList && this.fileList.length && this.fileList[0].raw) {
              formData.append('file', this.fileList[0].raw);
            }
            formData.append('requestJson', JSON.stringify({ ...this.detailRes, ...params }));

            if (this.isEdit) {
              if (this.type == 'metting') {
                uploadMeeting(formData)
                  .then((e) => {
                    this.$message({
                      message: '修改成功',
                      type: 'success'
                    });
                    this.closeMe();
                    this.updateList();
                  })
                  .catch((error) => {});
              } else if (this.type == 'training') {
                uploadTraining(formData)
                  .then((e) => {
                    this.$message({
                      message: '修改成功',
                      type: 'success'
                    });
                    this.closeMe();
                    this.updateList();
                  })
                  .catch((error) => {});
              }
            } else {
              if (this.type == 'metting') {
                createMeeting(formData)
                  .then((e) => {
                    this.$message({
                      message: '创建成功',
                      type: 'success'
                    });
                    this.closeMe();
                    this.updateList();
                  })
                  .catch((error) => {});
              } else if (this.type == 'training') {
                createTraining(formData)
                  .then((e) => {
                    this.$message({
                      message: '创建成功',
                      type: 'success'
                    });
                    this.closeMe();
                    this.updateList();
                  })
                  .catch((error) => {});
              }
            }
          } else {
            return false;
          }
        });
      }
    },
    onEditorChange(e) {
      this.richText = e;
    },
    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style scoped lang="scss">
.editor {
  width: 100%;
  height: 300px;
}
</style>
